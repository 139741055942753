import BaseEntityModelFilter from "./../../../general/BaseEntityModelFilter";
import store from "./../../../../store";

export default class UserWalletTransactionsFilter extends BaseEntityModelFilter {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setFilterInitialValue();
    this.activationTypeTokens =
      store.getters.generalSetting.userWalletTransactions
        .activationTypeTokens || [];
    // reports
    this.calendarSearchType = ""; //custom
    this.userWalletTransactionMethodToken = "";
    this.userWalletTransactionValueFrom = "";
    this.userWalletTransactionValueTo = "";
    this.userToken = "";
    this.userWalletTransactionTypeToken = "";
    this.userWalletTransactionToken = "";
    this.dateTimeStartSearch = "";
    this.dateTimeEndSearch = "";
    this.token = "";
    this.sendTo = "";
  }
  fillData(data) {
    if (data) {
      this.fillFilterData(data);
      this.userWalletTransactionMethodToken =
        data.userWalletTransactionMethodToken ?? "";
      this.userWalletTransactionValueFrom =
        data.userWalletTransactionValueFrom ?? "";
      this.userWalletTransactionValueTo =
        data.userWalletTransactionValueTo ?? "";
      this.userToken = data.userToken ?? "";
      this.userWalletTransactionTypeToken =
        data.userWalletTransactionTypeToken ?? "";
      this.userWalletTransactionToken = data.userWalletTransactionToken ?? "";
      this.dateTimeStartSearch = data.dateTimeStartSearch ?? "";
      this.dateTimeEndSearch = data.dateTimeEndSearch ?? "";
      this.token = data.token ?? "";
      this.sendTo = data.sendTo ?? "";
    } else {
      this.setInitialValue();
    }
  }
}
