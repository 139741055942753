<template>
  <div>
    <PreLoader v-if="isLoading" />

    <CustomBottomSheet
      refName="UserWalletTransactionAdd"
      size="xl"
      :headerText="$t('UserWalletTransactions.add')"
      :headerIcon="userWalletTransaction.icon"
      :clickToClose="false"
      :swipeAble="false"
      @opened="
        userWalletTransaction.setInitialValue();
        userWalletTransaction.userToken = filterData.userToken;
        userWalletTransaction.userWalletTransactionTypeToken =
          constantsListsData.listWalletTransactionType[0].itemToken;
      "
      @closed="
        userWalletTransaction.setInitialValue();
        filterData.setInitialValue();
        $emit('refresh');
        $emit('closed');
      "
    >
      <UserWalletTransactionForm
        v-if="!isLoading"
        id="add"
        :userWalletTransaction="userWalletTransaction"
        v-on:submitForm="addUserWalletTransaction()"
        bottomSheetName="UserWalletTransactionAdd"
        :submitName="$t('add')"
      />
    </CustomBottomSheet>
    <ConfirmClearData
      dialogName="UserWalletTransactionAdd"
      v-on:response="response($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreLoader from "./../../../../../components/general/PreLoader.vue";
import CustomBottomSheet from "./../../../../../components/general/CustomBottomSheet.vue";
import ConfirmClearData from "./../../../../../components/general/ConfirmClearData.vue";
import UserWalletTransactionForm from "./UserWalletTransactionForm.vue";
import { STATUS } from "./../../../../../utils/constants";
import { objectToFormData } from "./../../../../../utils/functions";
import apiUserWalletTransaction from "./../../../../../api/userWalletTransaction";
import generalMixin from "./../../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomBottomSheet,
    ConfirmClearData,
    UserWalletTransactionForm,
  },
  computed: {
    ...mapGetters(["constantsListsData"]),
  },
  props: ["userWalletTransaction", "filterData"],
  methods: {
    async addUserWalletTransaction() {
      this.isLoading = true;
      let formData = objectToFormData(this.userWalletTransaction);
      try {
        const response = await apiUserWalletTransaction.add(formData);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$bvModal.show("ConfirmClearData-UserWalletTransactionAdd");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    response(data) {
      this.$emit("refresh");
      switch (data) {
        case "yes":
          this.userWalletTransaction.setInitialValue();
          break;
        case "leave":
          this.userWalletTransaction.setInitialValue();
          this.closeBottomSheet("UserWalletTransactionAdd");
          break;
      }
    },
  },
};
</script>
