import { METHOD, request } from "./../utils/request";
import { StudentParents } from "./api";

async function add(data) {
  return request(StudentParents.add, METHOD.POST, data);
}
async function update(data) {
  return request(StudentParents.update, METHOD.POST, data);
}

export default {
  add,
  update,
};
