import BaseEntityModelFilter from "./../general/BaseEntityModelFilter";
import store from "./../../store";

export default class UserOrdersFilter extends BaseEntityModelFilter {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setFilterInitialValue();
    this.activationTypeTokens =
      store.getters.generalSetting.userOrders.activationTypeTokens ?? [];
    /*
userInfoDataInclude:true
userInfoDeliveryDataInclude:true
orderProductsDataInclude:true 
userFullCode:
 userOrderToken:
      */

    this.userToken = "";
    this.userDeliveryToken = "";
    this.deliveryStatusTypeToken = "";
    this.calendarSearchType = "";
    this.dateTimeStartSearch = "";
    this.dateTimeEndSearch = "";
  }
  fillData(data) {
    if (data) {
      this.fillFilterData(data);
      this.userToken = data.userToken ?? "";
      this.userDeliveryToken = data.userDeliveryToken ?? "";
      this.deliveryStatusTypeToken = data.deliveryStatusTypeToken ?? "";
      this.calendarSearchType = data.calendarSearchType ?? "";
      this.dateTimeStartSearch = data.dateTimeStartSearch ?? "";
      this.dateTimeEndSearch = data.dateTimeEndSearch ?? "";
    } else {
      this.setInitialValue();
    }
  }
}
