<template>
  <div class="row custom-cards">
    <CustomCard
      v-for="(user, index) in usersData"
      :key="index"
      :className="'col-sm-6 col-md-4 col-lg-3'"
      :title="user.userNameCurrent"
      :description="user.fullCode"
      :imagePath="user.userImagePath"
      :defaultImg="defaultImg"
      :index="++index + filterData.currentIndex"
    >
      <li>
        <button
          @click="setUserData(user)"
          v-b-modal.UserChangePassword
          :title="$t('changePassword')"
        >
          <img src="@/assets/images/changePassword.svg" />
        </button>
      </li>
      <li v-if="hasResetCode">
        <button
          @click="
            setUserTokenReset(user.userToken);
            setIsResetTypeLoginCode(true);
          "
          v-b-modal.UserLoginCodeReset
          :title="$t('UserLoginCodes.resetCode')"
        >
          <img src="@/assets/images/UserLoginCodes.svg" />
        </button>
      </li>
      <li v-if="hasResetDevice">
        <button
          @click="
            setUserTokenReset(user.userToken);
            setIsResetTypeLoginCode(false);
          "
          v-b-modal.UserLoginCodeReset
          :title="$t('UserLoginCodes.resetDevice')"
        >
          <img src="@/assets/images/pc.svg" />
        </button>
      </li>
      <li
        v-if="
          checkPrivilege(hasUserEducationalStage()) && modelName == 'students'
        "
      >
        <router-link
          :to="{
            name: 'UserEducationalStages',
            params: {
              userToken: user.userToken,
            },
          }"
          :title="$t('UserEducationalStages.modelName')"
        >
          <img
            src="@/assets/images/userEducationalStages.svg"
            class="icon-lg"
          />
        </router-link>
      </li>
      <li
        v-if="
          checkPrivilege(hasUserWalletTransaction()) && modelName == 'students'
        "
      >
        <router-link
          :to="{
            name: 'UserWalletTransactions',
            params: {
              userToken: user.userToken,
            },
          }"
          :title="$t('UserWalletTransactions.modelName')"
        >
          <img
            src="@/assets/images/userWalletTransactions.svg"
            class="icon-lg"
          />
        </router-link>
      </li>
      <li
        v-if="
          checkPrivilege(hasUserWalletTransactionAdd()) &&
          modelName == 'students'
        "
      >
        <button
          @click="
            setUserWalletFilter(user.userToken);
            openBottomSheet('UserWalletTransactionAdd');
          "
          :title="$t('UserWalletTransactions.add')"
        >
          <img src="@/assets/images/add-money-wallet-icon.svg" />
        </button>
      </li>
      <li
        v-if="checkPrivilege(hasStudentsIdReport()) && modelName == 'students'"
      >
        <button
          @click="
            setUserData(user);
            openBottomSheet('StudentsIdReportFilter');
          "
          :title="$t('Reports.studentsId')"
        >
          <img src="@/assets/images/cards.svg" />
        </button>
      </li>
      <li
        v-if="
          checkPrivilege(hasStudentsWalletReport()) && modelName == 'students'
        "
      >
        <button
          @click="
            setUserData(user);
            openBottomSheet('StudentsWalletReportFilter');
          "
          :title="$t('Reports.studentsWallet')"
        >
          <img src="@/assets/images/report.svg" />
        </button>
      </li>
      <li
        v-if="
          checkPrivilege(hasUserWalletTransactionReport()) &&
          modelName == 'students'
        "
      >
        <button
          @click="
            setUserData(user);
            openBottomSheet('StudentsWalletTransactionsReportFilter');
          "
          :title="$t('Reports.studentsWalletTransaction')"
        >
          <img src="@/assets/images/treasuries-transactions.svg" />
        </button>
      </li>
      <li
        v-if="checkPrivilege(hasUserOrderReport()) && modelName == 'students'"
      >
        <button
          @click="
            setUserData(user);
            openBottomSheet('StudentsOrdersReportFilter');
          "
          :title="$t('Reports.studentsOrders')"
        >
          <img src="@/assets/images/userOrders.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setUserData(user);
            openBottomSheet('UserInfo');
          "
          :title="$t('info')"
        >
          <img src="@/assets/images/info.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setUserData(user);
            openBottomSheet('UserQRCode');
          "
          :title="$t('general.qrCode')"
        >
          <img src="@/assets/images/qr-code.svg" />
        </button>
      </li>
      <li v-if="hasPrivilegeEdit">
        <button
          @click="
            setUserData(user);
            openBottomSheet('UserUpdate');
          "
          :title="$t('edit')"
        >
          <img src="@/assets/images/pencil.svg" />
        </button>
      </li>
      <li v-if="hasPrivilegeStudentParentrEdit">
        <button
          @click="
            updateWithParents(user);
            openBottomSheet('UserUpdate');
          "
          :title="$t('StudentParents.edit')"
        >
          <img src="@/assets/images/user-group-edit.svg" />
        </button>
      </li>
      <li v-if="hasPrivilegeFinaleDelete">
        <button
          v-b-modal.UserDelete
          :title="$t('delete')"
          @click="setUserData(user)"
        >
          <img src="@/assets/images/trash.svg" />
        </button>
      </li>
      <li v-if="hasPrivilegeChangeActivationType">
        <button
          v-b-modal.UserChangeActivationType
          :title="$t('changeActivationType')"
          @click="setUserData(user)"
        >
          <img src="@/assets/images/changeActivationType.svg" />
        </button>
      </li>
      <li>
        <button
          @click="
            setUserData(user);
            openBottomSheet('ActionsData');
          "
          :title="$t('actionsData.modelName')"
        >
          <img src="@/assets/images/actions-data.svg" />
        </button>
      </li>
    </CustomCard>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
} from "./../../utils/functions";
import {
  hasAdminsEgyptEdit,
  hasStudentsIdReport,
  hasStudentsWalletReport,
  hasUserWalletTransactionReport,
  hasUserOrderReport,
  hasUserWalletTransaction,
  hasUserWalletTransactionAdd,
  hasUserEducationalStage,
  hasAdminsEgyptFinaleDelete,
  hasAdminsEgyptChangeActivationType,
  hasMasterAdminEdit,
  hasMasterAdminFinaleDelete,
  hasMasterAdminChangeActivationType,
  hasEmployeeEdit,
  hasEmployeeFinaleDelete,
  hasEmployeeChangeActivationType,
  hasStudentEdit,
  hasStudentFinaleDelete,
  hasStudentChangeActivationType,
  hasParentEdit,
  hasParentFinaleDelete,
  hasParentChangeActivationType,
  hasAdminsEgyptLoginCodeResetCode,
  hasAdminsEgyptLoginCodeResetDevice,
  hasMasterAdminLoginCodeResetCode,
  hasMasterAdminLoginCodeResetDevice,
  hasEmployeeLoginCodeResetCode,
  hasEmployeeLoginCodeResetDevice,
  hasStudentLoginCodeResetCode,
  hasStudentLoginCodeResetDevice,
  hasParentLoginCodeResetCode,
  hasParentLoginCodeResetDevice,
} from "./../../utils/privilegeHelper";
import CustomCard from "./../general/CustomCard.vue";
import generalMixin from "./../../utils/generalMixin";

export default {
  name: "UsersCards",
  mixins: [generalMixin],
  components: {
    CustomCard,
  },
  props: ["usersData", "filterData", "defaultImg", "modelName"],
  data() {
    return {
      storageSpacesRoute: this.$route.meta.StorageSpacesRoute,
      userTypeToken: "",
    };
  },
  methods: {
    async updateAndGetUserFinanceStatistic(userToken) {
      this.$emit("updateAndGetUserFinanceStatistic", userToken);
    },
    setUserTokenReset(token) {
      this.$emit("setUserTokenReset", token);
    },
    setUserData(user) {
      this.$emit("setUserData", user);
    },
    setUserWalletFilter(token) {
      this.$emit("setUserWalletFilter", token);
    },
    goToUserProfileTabs(user) {
      this.userTypeToken = user.userTypeToken;
      let routeName = "";

      if (this.hasUserFamily()) {
        routeName = "UserFamilies";
      } else if (this.hasUserQualification()) {
        routeName = "UserQualifications";
      } else if (this.hasUserPersonalCard()) {
        routeName = "UserPersonalCards";
      } else if (this.hasUserTrainingCourse()) {
        routeName = "UserTrainingCourses";
      } else if (this.hasUserExperience()) {
        routeName = "UserExperiences";
      } else if (this.hasUserScientificDegree()) {
        routeName = "UserScientificDegrees";
      }

      this.$router
        .push({
          name: routeName,
          params: {
            userTypeToken: user.userTypeToken,
            userToken: user.userToken,
          },
        })
        .catch(() => {});
    },
    checkPrivilege,
    isDataExist,
    fullPathFileFromServer,
    hasAdminsEgyptEdit,
    hasStudentsIdReport,
    hasStudentsWalletReport,
    hasUserWalletTransactionReport,
    hasUserOrderReport,
    hasUserWalletTransaction,
    hasUserWalletTransactionAdd,
    hasUserEducationalStage,
    hasAdminsEgyptFinaleDelete,
    hasAdminsEgyptChangeActivationType,
    hasMasterAdminEdit,
    hasMasterAdminFinaleDelete,
    hasMasterAdminChangeActivationType,
    hasEmployeeEdit,
    hasEmployeeFinaleDelete,
    hasEmployeeChangeActivationType,
    hasStudentEdit,
    hasStudentFinaleDelete,
    hasStudentChangeActivationType,
    hasParentEdit,
    hasParentFinaleDelete,
    hasParentChangeActivationType,
  },
  computed: {
    hasPrivilegeStudentParentrEdit() {
      let status = false;
      switch (this.modelName) {
        case "students":
          status = checkPrivilege(this.hasStudentEdit());
          break;
      }
      return status;
    },
    hasPrivilegeEdit() {
      let status = false;
      switch (this.modelName) {
        case "adminsEgypt":
          status = checkPrivilege(this.hasAdminsEgyptEdit());
          break;
        case "masterAdmins":
          status = checkPrivilege(this.hasMasterAdminEdit());
          break;
        case "employees":
          status = checkPrivilege(this.hasEmployeeEdit());
          break;
        case "students":
          status = checkPrivilege(this.hasStudentEdit());
          break;

        case "parents":
          status = checkPrivilege(this.hasParentEdit());
          break;
      }
      return status;
    },
    hasPrivilegeFinaleDelete() {
      let status = false;
      switch (this.modelName) {
        case "adminsEgypt":
          status = checkPrivilege(this.hasAdminsEgyptFinaleDelete());
          break;
        case "masterAdmins":
          status = checkPrivilege(this.hasMasterAdminFinaleDelete());
          break;
        case "employees":
          status = checkPrivilege(this.hasEmployeeFinaleDelete());
          break;
        case "students":
          status = checkPrivilege(this.hasStudentFinaleDelete());
          break;
        case "parents":
          status = checkPrivilege(this.hasParentFinaleDelete());
          break;
      }
      return status;
    },
    hasPrivilegeChangeActivationType() {
      let status = false;
      switch (this.modelName) {
        case "adminsEgypt":
          status = checkPrivilege(this.hasAdminsEgyptChangeActivationType());
          break;
        case "masterAdmins":
          status = checkPrivilege(this.hasMasterAdminChangeActivationType());
          break;
        case "employees":
          status = checkPrivilege(this.hasEmployeeChangeActivationType());
          break;
        case "students":
          status = checkPrivilege(this.hasStudentChangeActivationType());
          break;
        case "suppliers":
          status = checkPrivilege(this.hasSupplierChangeActivationType());
          break;
        case "parents":
          status = checkPrivilege(this.hasParentChangeActivationType());
          break;
      }
      return status;
    },

    hasResetCode() {
      let status = false;
      switch (this.modelName) {
        case "adminsEgypt":
          status = checkPrivilege(hasAdminsEgyptLoginCodeResetCode());
          break;
        case "masterAdmins":
          status = checkPrivilege(hasMasterAdminLoginCodeResetCode());
          break;
        case "employees":
          status = checkPrivilege(hasEmployeeLoginCodeResetCode());
          break;
        case "students":
          status = checkPrivilege(hasStudentLoginCodeResetCode());
          break;

        case "parents":
          status = checkPrivilege(hasParentLoginCodeResetCode());
          break;
      }
      return status;
    },
    hasResetDevice() {
      let status = false;
      switch (this.modelName) {
        case "adminsEgypt":
          status = checkPrivilege(hasAdminsEgyptLoginCodeResetDevice());
          break;
        case "masterAdmins":
          status = checkPrivilege(hasMasterAdminLoginCodeResetDevice());
          break;
        case "employees":
          status = checkPrivilege(hasEmployeeLoginCodeResetDevice());
          break;
        case "students":
          status = checkPrivilege(hasStudentLoginCodeResetDevice());
          break;

        case "parents":
          status = checkPrivilege(hasParentLoginCodeResetDevice());
          break;
      }
      return status;
    },
  },
};
</script>
