<template>
  <CustomBottomSheet
    :refName="'StudentsOrdersReportFilter'"
    size="xl"
    :headerText="$t('Reports.studentsOrders')"
    :headerIcon="icon"
    @opened="
      setFilter();
      getDialogs();
    "
  >
    <div class="report-container">
      <div class="row report-filter">
        <CustomSelectBox
          :className="'col-md-6'"
          :id="`userDeliveryToken`"
          :value="userOrders.filterData.userDeliveryToken"
          :options="userDeliveryTokenOptions"
          v-on:changeValue="userOrders.filterData.userDeliveryToken = $event"
          :title="$t('SalesOutletAgents.select')"
          :imgName="'sales.svg'"
        />
        <CustomSelectBox
          :className="'col-md-6'"
          :id="`deliveryStatusTypeToken`"
          :value="userOrders.filterData.deliveryStatusTypeToken"
          :options="deliveryStatusTypeTokenOptions"
          v-on:changeValue="
            userOrders.filterData.deliveryStatusTypeToken = $event
          "
          :title="$t('general.state')"
          :imgName="'type.svg'"
        />
        <CustomSelectBox
          :className="'col-md-6'"
          :id="`userToken`"
          :value="userOrders.filterData.userToken"
          :options="userTokenOptions"
          v-on:changeValue="userOrders.filterData.userToken = $event"
          :title="$t('students.select')"
          :imgName="'students.svg'"
        />
        <CustomInput
          :className="'col-md-6'"
          :id="`sendTo`"
          :value="userOrders.filterData.sendTo"
          v-on:changeValue="userOrders.filterData.sendTo = $event"
          :title="$t('Reports.sendTo')"
          :imgName="'email.svg'"
        />

        <DateTimePicker
          class="col-md-6"
          :id="`userOrders-dateTimeStartSearch`"
          type="dateTime"
          :value="userOrders.filterData.dateTimeStartSearch"
          v-on:changeValue="
            userOrders.filterData.dateTimeStartSearch = $event.dateTime
          "
          :title="$t('Reports.dateTimeFrom')"
          :language="language"
        />

        <DateTimePicker
          class="col-md-6"
          :id="`userOrders-dateTimeEndSearch`"
          type="dateTime"
          :value="userOrders.filterData.dateTimeEndSearch"
          v-on:changeValue="
            userOrders.filterData.dateTimeEndSearch = $event.dateTime
          "
          :title="$t('Reports.dateTimeTo')"
          :language="language"
        />
      </div>
      <div
        v-if="reportUrl"
        class="row report-result animate__animated animate__fadeInDownBig"
      >
        <embed
          type="application/pdf"
          :src="reportUrl"
          width="400"
          height="300"
          id="pdf"
        />
      </div>
    </div>

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="getReport">
        <img
          src="@/assets/images/check-icon.svg"
          :title="$t('Reports.print')"
        />
      </div>
      <div
        @click.prevent="closeBottomSheet('StudentsOrdersReportFilter')"
        class="icon-cancel"
      >
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "@/components/general/CustomBottomSheet.vue";
import CustomSelectBox from "@/components/general/CustomSelectBox.vue";
import DateTimePicker from "@/components/general/DateTimePicker.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import generalMixin from "@/utils/generalMixin";
import icon from "@/assets/images/students.svg";
import { STATUS } from "@/utils/constants";
import UserOrders from "./../../../models/userOrders/UserOrders";
import apiUserOrder from "./../../../api/userOrder";
import {
  getFullFilePathFromServer,
  getLanguage,
} from "./../../../utils/functions";
import { getUsersDialog } from "./../../../utils/dialogsOfApi";
import { getDialogOfDeliveryStatusType } from "./../../../utils/dialogsOfConstantsLists";
import { USER_TYPE, LOCAL_JOB_TYPE } from "./../../../utils/constantLists";

export default {
  mixins: [generalMixin],
  data() {
    return {
      reportUrl: "",
      isLoading: false,
      language: getLanguage(),
      userOrders: new UserOrders(),
      icon,
      userTokenOptions: [],
      userDeliveryTokenOptions: [],
      deliveryStatusTypeTokenOptions: getDialogOfDeliveryStatusType() || [],
    };
  },
  components: {
    CustomBottomSheet,
    CustomSelectBox,
    DateTimePicker,
    CustomInput,
  },
  props: {
    userToken: {
      type: String,
      default: "",
    },
  },
  methods: {
    setFilter() {
      this.reportUrl = "";
      this.userOrders.filterData.setInitialValue();
      this.userOrders.filterData.userToken = this.userToken;
    },
    async getDialogs() {
      this.getUsersDialog();
    },
    async getUsersDialog() {
      this.isLoading = true;
      this.$emit("isLoading", true);
      let params = {
        modelName: "students",
        userTypeToken: USER_TYPE.Student,
      };
      this.userTokenOptions = await getUsersDialog(params);
      this.isLoading = false;
      this.$emit("isLoading", false);
    },
    async getSalesOutletAgentsDialog() {
      this.isLoading = true;
      this.$emit("isLoading", true);
      let params = {
        modelName: "employee",
        userTypeToken: USER_TYPE.Employee,
        userLocalJobToken: LOCAL_JOB_TYPE.SalesOutletAgent,
      };
      this.userDeliveryTokenOptions = await getUsersDialog(params);
      this.isLoading = false;
      this.$emit("isLoading", false);
    },

    async getReport() {
      this.$emit("isLoading", true);
      this.reportUrl = "";
      try {
        if (
          this.userOrders.filterData.dateTimeStartSearch ||
          this.userOrders.filterData.dateTimeEndSearch
        )
          this.userOrders.filterData.calendarSearchType = "CST-5";
        const response = await apiUserOrder.getReport(
          this.userOrders.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          if (response.data.reportPath) {
            this.reportUrl = getFullFilePathFromServer(
              response.data.reportPath
            );
          } else {
            this.showMsg(this.$t("Reports.noReports"));
          }
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        console.log(error);
        this.showMsg(this.$t("errorCatch"));
      }
      this.$emit("isLoading", false);
    },
    async created() {},
  },
};
</script>
