import store from "./../../store";
import UserStudent from "./UserStudent";
// import UserParentModel from "./UserParentModel";

export default class StudentParentModel {
  constructor() {
    this.setInitialValue();
  }

  setInitialValue() {
    this.userAuthorizeToken = store.getters.userAuthorizeToken;
    this.userStudentData = new UserStudent();
    this.userParentsData = [];
  }
  fillData(data) {
    if (data) {
      this.userAuthorizeToken = store.getters.userAuthorizeToken;
      this.userStudentData.fillData(data.userStudentData);
      this.userParentsData = data.userParentsData ?? [];
    } else {
      // comment
      this.setInitialValue();
    }
  }
}
//#endregion model
