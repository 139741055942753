import UserProfile from "./UserProfile";

export default class StudentParentModel {
  constructor() {
    this.setInitialValue();
  }

  setInitialValue() {
    this.userToken = "D5B11D06-C1B3-4EE6-A4A4-08DA9C965A0B"; //in update
    this.fullCode = "";
    this.userNameAr = "";
    this.userNameEn = "";
    this.userNameUnd = "";
    this.userPhoneCountryCode = "";
    this.userPhoneCountryCodeName = "";
    this.userPhone = "";
    this.userEmail = "";
    this.userName = "";
    this.userProfileData = new UserProfile();
  }
  fillData(data) {
    if (data) {
      this.userToken = data.userToken ?? "D5B11D06-C1B3-4EE6-A4A4-08DA9C965A0B";
      this.fullCode = data.fullCode ?? "";
      this.userNameAr = data.userNameAr ?? "";
      this.userNameEn = data.userNameEn ?? "";
      this.userNameUnd = data.userNameUnd ?? "";
      this.userPhoneCountryCode = data.userPhoneCountryCode ?? "";
      this.userPhoneCountryCodeName = data.userPhoneCountryCodeName ?? "";
      this.userPhone = data.userPhone ?? "";
      this.userEmail = data.userEmail ?? "";
      this.userName = data.userName ?? "";
      this.userProfileData.fillData(data.userProfileData);
    } else {
      // comment
      this.setInitialValue();
    }
  }
}
//#endregion model
