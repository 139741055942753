import UserProfile from "./UserProfile";

export default class UserParent {
  constructor() {
    this.setInitialValue();
  }

  setInitialValue() {
    this.userNameAr = "";
    this.userNameEn = "";
    this.userNameUnd = "";
    this.userPhoneCountryCode = "";
    this.userPhoneCountryCodeName = "";
    this.userPhone = "";
    this.userProfileData = new UserProfile();
  }
  fillData(data) {
    if (data) {
      this.userNameAr = data.userNameAr ?? "";
      this.userNameEn = data.userNameEn ?? "";
      this.userNameUnd = data.userNameUnd ?? "";
      this.userPhoneCountryCode = data.userPhoneCountryCode ?? "";
      this.userPhoneCountryCodeName = data.userPhoneCountryCodeName ?? "";
      this.userPhone = data.userPhone ?? "";
      this.userProfileData.fillData(data.userProfileData);
    } else {
      // comment
      this.setInitialValue();
    }
  }
}
//#endregion model
