<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <form autocomplete="off">
      <div class="row">
        <CustomFileInput
          :className="'col-12'"
          @changeValue="userWalletTransaction.mediaFile = $event.file"
          :defaultImg="
            fullPathFileFromServer(
              userWalletTransaction.userWalletTransactionImagePath,
              userWalletTransaction.defaultImg
            )
          "
        />
      </div>

      <div class="my-card">
        <div class="row">
          <CustomInput
            :className="'col-md-6'"
            :id="`${id}-fullCode`"
            :value="userWalletTransaction.fullCode"
            v-on:changeValue="userWalletTransaction.fullCode = $event"
            :title="$t('UserWalletTransactions.code')"
            :imgName="'code.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="`${id}-userToken`"
            :value="userWalletTransaction.userToken"
            :options="userTokenOptions"
            v-on:changeValue="userWalletTransaction.userToken = $event"
            :title="$t('students.select')"
            :imgName="'students.svg'"
          />
          <CustomSelectBox
            :className="'col-md-6'"
            :id="`${id}-userWalletTransactionTypeToken`"
            :errors="errors_userWalletTransactionTypeToken"
            :value="userWalletTransaction.userWalletTransactionTypeToken"
            :options="userWalletTransactionTypeTokenOptions"
            v-on:changeValue="
              userWalletTransaction.userWalletTransactionTypeToken = $event;
              $v.userWalletTransaction.userWalletTransactionTypeToken.$touch();
            "
            :title="$t('UserWalletTransactions.type')"
            :imgName="'type.svg'"
          />

          <CustomInputFloat
            :className="'col-md-6'"
            :id="`${id}-userWalletTransactionValue`"
            :errors="errors_userWalletTransactionValue"
            :value="userWalletTransaction.userWalletTransactionValue"
            v-on:changeValue="
              userWalletTransaction.userWalletTransactionValue = $event;
              $v.userWalletTransaction.userWalletTransactionValue.$touch();
            "
            :title="$t('general.value')"
            :imgName="'money.svg'"
          />

          <CustomSelectBox
            :className="'col-md-6'"
            :id="`${id}-userWalletTransactionMethodToken`"
            :value="userWalletTransaction.userWalletTransactionMethodToken"
            :options="userWalletTransactionMethodTokenOptions"
            v-on:changeValue="
              userWalletTransaction.userWalletTransactionMethodToken = $event
            "
            :title="$t('paymentMethod')"
            :imgName="'paymentMethods.svg'"
          />

          <TextArea
            :className="'col-md-6'"
            :id="`${id}-userWalletTransactionNotes`"
            :value="userWalletTransaction.userWalletTransactionNotes"
            v-on:changeValue="
              userWalletTransaction.userWalletTransactionNotes = $event
            "
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>

      <div class="form-actions">
        <div class="icon-submit" @click.prevent="submitForm">
          <img src="@/assets/images/check-icon.svg" :title="submitName" />
        </div>
        <div
          @click.prevent
          class="icon-cancel"
          v-b-modal="`ConfirmCloseSheet-${bottomSheetName}`"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  fullPathFileFromServer,
  bottomSheetScrollToTop,
} from "./../../../../../utils/functions";
import PreLoader from "./../../../../../components/general/PreLoader.vue";
import CustomFileInput from "./../../../../../components/general/CustomFileInput.vue";
import CustomInput from "./../../../../../components/general/CustomInput.vue";
import CustomInputFloat from "./../../../../../components/general/CustomInputFloat.vue";
import CustomSelectBox from "./../../../../../components/general/CustomSelectBox.vue";
import TextArea from "./../../../../../components/general/TextArea.vue";
import {
  getDialogOfWalletTransactionType,
  getDialogOflistPaymentMethod,
} from "./../../../../../utils/dialogsOfConstantsLists";
import { USER_TYPE } from "./../../../../../utils/constantLists";
import { getUsersDialog } from "./../../../../../utils/dialogsOfApi";
import generalMixin from "./../../../../../utils/generalMixin";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [generalMixin, validationMixin],
  components: {
    PreLoader,
    CustomFileInput,
    CustomInput,
    CustomInputFloat,
    CustomSelectBox,
    TextArea,
  },
  data() {
    return {
      userTokenOptions: [],
      userWalletTransactionTypeTokenOptions:
        getDialogOfWalletTransactionType().slice(0, 2),
      userWalletTransactionMethodTokenOptions: getDialogOflistPaymentMethod(),
    };
  },
  props: {
    userWalletTransaction: {
      type: Object,
    },
    submitName: {
      type: String,
    },
    id: {
      type: String,
      default: "id",
    },
    deleteFileStatus: {
      type: Boolean,
      default: false,
    },
    bottomSheetName: {
      type: String,
      default: "",
    },
  },
  validations: {
    userWalletTransaction: {
      userWalletTransactionTypeToken: { required },
      userWalletTransactionValue: { required },
    },
  },
  computed: {
    errors_userWalletTransactionValue() {
      let errors = [];
      if (this.$v.userWalletTransaction.userWalletTransactionValue.$error) {
        if (!this.$v.userWalletTransaction.userWalletTransactionValue.required)
          errors.push(this.$t("validation.fieldIsRequired"));
      }
      return errors;
    },
    errors_userWalletTransactionTypeToken() {
      let errors = [];
      if (this.$v.userWalletTransaction.userWalletTransactionTypeToken.$error) {
        if (
          !this.$v.userWalletTransaction.userWalletTransactionTypeToken.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
      }
      return errors;
    },
  },
  methods: {
    async getStudentsDialog() {
      this.isLoading = true;
      let params = {
        modelName: "students",
        userTypeToken: USER_TYPE.Student,
      };
      this.userTokenOptions = await getUsersDialog(params);
      this.isLoading = false;
    },
    async submitForm() {
      bottomSheetScrollToTop();
      this.$v.$touch();
      if (
        this.$v.userWalletTransaction.userWalletTransactionValue.required &&
        this.$v.userWalletTransaction.userWalletTransactionTypeToken.required
      ) {
        this.$emit("submitForm");
      }
    },

    fullPathFileFromServer,
  },

  created() {
    this.getStudentsDialog();
  },
};
</script>
