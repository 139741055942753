import UserParent from "./UserParent";

export default class UserParentModel {
  constructor() {
    this.setInitialValue();
  }

  setInitialValue() {
    this.parentRelativeRelationTypeToken = "";
    this.userParentData = new UserParent();
  }
  fillData(data) {
    if (data) {
      this.parentRelativeRelationTypeToken =
        data.parentRelativeRelationTypeToken ?? "";
      this.userParentData.fillData(data.userParentData);
    } else {
      // comment
      this.setInitialValue();
    }
  }
}
//#endregion model
