import GeneralModel from "./../../../general/GeneralModel";
import User from "./../../../users/User";
import UserWalletTransaction from "./UserWalletTransaction";
import UserWalletTransactionsFilter from "./UserWalletTransactionsFilter";

export default class UserWalletTransactions extends GeneralModel {
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setGeneralModelInitialValue();
    this.userWalletTransactionsData = [];
    this.userData = new User();
    this.userWalletTransaction = new UserWalletTransaction();
    this.filterData = new UserWalletTransactionsFilter();
  }
  fillData(data) {
    if (data) {
      this.fillGeneralModelData(data);
      this.userWalletTransactionsData = data.userWalletTransactionsData || [];
      this.userData.fillData(data.userData);
      let filter = { ...this.filterData, ...data.pagination };
      this.filterData.fillData(filter);
    } else {
      this.setInitialValue();
    }
  }
}
