import BaseEntityModel from "./../general/BaseEntityModel";
import defaultImg from "@/assets/images/userOrders.svg";

export default class UserOrder extends BaseEntityModel {
  icon = defaultImg;
  defaultImg = defaultImg;
  constructor() {
    super();
    this.setInitialValue();
  }
  setInitialValue() {
    this.setInitialValueBaseEntityModel();
    this.userOrderToken = "";
  }
  fillData(data) {
    if (data) {
      this.fillDataBaseEntityModel(data);
      this.userOrderToken = data.userOrderToken ?? "";
    } else {
      this.setInitialValue();
    }
  }
}
//#endregion model
