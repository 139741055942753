import { METHOD, request } from "./../utils/request";
import { UserOrders } from "./api";

async function getReport(params) {
  return request(UserOrders.getReport, METHOD.GET, params);
}

export default {
  getReport,
};
