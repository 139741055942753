<template>
  <div class="">
    <form autocomplete="off">
      <template>
        <div class="row">
          <CustomFileInput
            :className="'col-12'"
            @changeValue="user.mediaFile = $event.file"
            :defaultImg="
              fullPathFileFromServer(user.userImagePath, user.defaultImg)
            "
            :deleteFileStatus="deleteFileStatus && !user.userImageIsDefault"
            v-on:deleteFile="$emit('deleteFile')"
          />
        </div>

        <div class="my-card">
          <div class="row">
            <template>
              <CustomInput
                :className="'col-md-6'"
                :id="`${id}-userNameAr`"
                :errors="errors_userNameAr"
                :value="user.userNameAr"
                v-on:changeValue="
                  user.userNameAr = $event;
                  $v.user.userNameAr.$touch();
                "
                :title="$t('Users.nameAr')"
                :imgName="'users.svg'"
                :disabledStatus="disabledStatus"
              />
              <CustomInput
                :className="'col-md-6'"
                :id="`${id}-userNameEn`"
                :errors="errors_userNameEn"
                :value="user.userNameEn"
                v-on:changeValue="
                  user.userNameEn = $event;
                  $v.user.userNameEn.$touch();
                "
                :title="$t('Users.nameEn')"
                :imgName="'users.svg'"
                :disabledStatus="disabledStatus"
              />
              <CustomInput
                :className="'col-md-6'"
                :id="`${id}-userNameUnd`"
                :value="user.userNameUnd"
                v-on:changeValue="user.userNameUnd = $event"
                :title="$t('Users.nameUnd')"
                :imgName="'users.svg'"
                :disabledStatus="disabledStatus"
              />
              <CustomInput
                :className="'col-md-6'"
                :id="`${id}-userName`"
                :value="user.userName"
                v-on:changeValue="user.userName = $event"
                :title="$t('Users.userName')"
                :imgName="'users.svg'"
                :disabledStatus="disabledStatus"
              />

              <CustomInput
                :className="'col-md-6'"
                :id="`${id}-email`"
                :errors="errors_userEmail"
                :value="user.userEmail"
                v-on:changeValue="
                  user.userEmail = $event;
                  $v.user.userEmail.$touch();
                "
                :title="$t('Users.email')"
                :imgName="'email.svg'"
                :disabledStatus="disabledStatus"
              />
              <CustomPhonePicker
                :className="'col-md-6'"
                :id="`${id}-phone`"
                :countryCodeName="user.userPhoneCountryCodeName"
                :countryCode="user.userPhoneCountryCode"
                :phoneNumber="user.userPhone"
                :title="$t('Users.phone')"
                :imgName="'phone.svg'"
                v-on:changeValue="setPhone($event)"
              />

              <CustomInputPassword
                v-if="submitName != $t('edit')"
                :className="'col-md-12'"
                :id="`${id}-userPassword`"
                :value="user.userPassword"
                v-on:changeValue="user.userPassword = $event"
                :title="$t('Users.password')"
                :imgName="'passwordlock.svg'"
              />
              <TextArea
                :className="'col-md-12'"
                :id="`${id}-userGeneralNotes`"
                :value="user.userProfileData.userGeneralNotes"
                :title="$t('notes')"
                :imgName="'notes.svg'"
                v-on:changeValue="
                  user.userProfileData.userGeneralNotes = $event
                "
                :disabledStatus="disabledStatus"
              />

              <CustomSelectBox
                :className="
                  userTypeToken == USER_TYPE.Employee ? 'col-md-6' : 'col-md-12'
                "
                :id="`${id}-establishmentRoleToken`"
                :value="
                  !disabledStatus
                    ? user.establishmentRoleToken
                    : user.establishmentRoleData.establishmentRoleNameCurrent
                "
                :options="establishmentRoleTokenOptions"
                v-on:changeValue="user.establishmentRoleToken = $event"
                :title="$t('EstablishmentRoles.select')"
                :imgName="'type.svg'"
                :disabledStatus="disabledStatus"
              />
              <CustomSelectBox
                v-if="userTypeToken == USER_TYPE.Employee"
                :className="'col-md-6'"
                :id="`${id}-userLocalJobToken`"
                :value="user.userProfileData.userLocalJobToken"
                :options="userLocalJobTokenOptions"
                v-on:changeValue="
                  user.userProfileData.userLocalJobToken = $event
                "
                :title="$t('Jobs.name')"
                :imgName="'jobs.svg'"
                :disabledStatus="disabledStatus"
              />
              <CustomSelectBox
                :className="'col-md-6'"
                :id="`${id}-userGenderToken`"
                :value="user.userProfileData.userGenderToken"
                :options="userGenderTokenOptions"
                v-on:changeValue="user.userProfileData.userGenderToken = $event"
                :title="$t('Users.gender')"
                :imgName="'gender.svg'"
                :disabledStatus="disabledStatus"
              />
              <CustomSelectBox
                :className="'col-md-6'"
                :id="`${id}-userReligionToken`"
                :value="user.userProfileData.userReligionToken"
                :options="userReligionTokenOptions"
                v-on:changeValue="
                  user.userProfileData.userReligionToken = $event
                "
                :title="$t('Religions.name')"
                :imgName="'religions.svg'"
                :disabledStatus="disabledStatus"
              />
              <template v-if="updateWithParents">
                <CustomSelectBox
                  :className="'col-md-12'"
                  :id="`${id}-userProfileData-userEducationalStageToken`"
                  :value="user.userProfileData.userEducationalStageToken"
                  :options="userEducationalStageTokenOptions"
                  v-on:changeValue="
                    user.userProfileData.userEducationalStageToken = $event
                  "
                  :title="$t('EducationalStages.select')"
                  :imgName="'educationalStages.svg'"
                />
                <CustomInputFloat
                  :className="'col-md-6'"
                  :id="`${id}-userProfileData-dailyPurchaseLimit`"
                  :value="user.userProfileData.dailyPurchaseLimit"
                  v-on:changeValue="
                    user.userProfileData.dailyPurchaseLimit = $event
                  "
                  :title="$t('Users.dailyPurchaseLimit')"
                  :imgName="'number.svg'"
                />
                <CustomInputFloat
                  :className="'col-md-6'"
                  :id="`${id}-userProfileData-monthlyPurchaseLimit`"
                  :value="user.userProfileData.monthlyPurchaseLimit"
                  v-on:changeValue="
                    user.userProfileData.monthlyPurchaseLimit = $event
                  "
                  :title="$t('Users.monthlyPurchaseLimit')"
                  :imgName="'number.svg'"
                />
              </template>
            </template>
          </div>
        </div>
      </template>
      <template v-if="updateWithParents">
        <div class="my-card">
          <span class="my-card-title">{{ $t("parents.modelName") }}</span>

          <div
            v-if="user.userParentsData.length == 0"
            class="my-card-no-content"
          >
            {{ $t("parents.thereAreNoOne") }}
          </div>
          <template v-else>
            <div
              class="my-card"
              v-for="(parent, indexParent) in user.userParentsData"
              :key="indexParent"
            >
              <span class="my-card-title">{{ indexParent + 1 }}</span>

              <span class="remove-slice-container">
                <button
                  @click="removeSliceParent(indexParent)"
                  type="button"
                  class="btn btn-remove-slice"
                >
                  ×
                </button>
              </span>

              <div class="row">
                <CustomSelectBox
                  :className="'col-md-6'"
                  :id="`${id}-[${indexParent}]parentRelativeRelationTypeToken`"
                  :value="parent.parentRelativeRelationTypeToken"
                  :options="parentRelativeRelationTypeTokenOptions"
                  v-on:changeValue="
                    parent.parentRelativeRelationTypeToken = $event
                  "
                  :title="$t('selectRelativeRelation')"
                  :imgName="'RelativeRelationTypes.svg'"
                />
                <CustomInput
                  :className="'col-md-6'"
                  :id="`${id}-[${indexParent}]userParentData-userNameAr`"
                  :value="parent.userParentData.userNameAr"
                  v-on:changeValue="parent.userParentData.userNameAr = $event"
                  :title="$t('Users.nameAr')"
                  :imgName="'parents.svg'"
                />
                <CustomInput
                  :className="'col-md-6'"
                  :id="`${id}-[${indexParent}]userParentData-userNameEn`"
                  :value="parent.userParentData.userNameEn"
                  v-on:changeValue="parent.userParentData.userNameEn = $event"
                  :title="$t('Users.nameEn')"
                  :imgName="'parents.svg'"
                />
                <CustomInput
                  :className="'col-md-6'"
                  :id="`${id}-[${indexParent}]userParentData-userNameUnd`"
                  :value="parent.userParentData.userNameUnd"
                  v-on:changeValue="parent.userParentData.userNameUnd = $event"
                  :title="$t('Users.nameUnd')"
                  :imgName="'parents.svg'"
                />
                <CustomPhonePicker
                  :className="'col-md-12'"
                  :id="`${id}-[${indexParent}]userParentData-phone`"
                  :countryCodeName="
                    parent.userParentData.userPhoneCountryCodeName
                  "
                  :countryCode="parent.userParentData.userPhoneCountryCode"
                  :phoneNumber="parent.userParentData.userPhone"
                  :title="$t('Users.phone')"
                  :imgName="'phone.svg'"
                  v-on:changeValue="
                    parent.userParentData.userPhoneCountryCodeName =
                      $event.countryCodeName;
                    parent.userParentData.userPhoneCountryCode =
                      $event.countryCode;
                    parent.userParentData.userPhone = $event.phoneNumber;
                  "
                />
                <CustomSelectBox
                  :className="'col-md-6'"
                  :id="`${id}-[${indexParent}]userParentData-userGenderToken`"
                  :value="parent.userParentData.userProfileData.userGenderToken"
                  :options="userGenderTokenOptions"
                  v-on:changeValue="
                    parent.userParentData.userProfileData.userGenderToken =
                      $event
                  "
                  :title="$t('Users.gender')"
                  :imgName="'gender.svg'"
                />
                <CustomSelectBox
                  :className="'col-md-6'"
                  :id="`${id}-[${indexParent}]userParentData-userReligionToken`"
                  :value="
                    parent.userParentData.userProfileData.userReligionToken
                  "
                  :options="userReligionTokenOptions"
                  v-on:changeValue="
                    parent.userParentData.userProfileData.userReligionToken =
                      $event
                  "
                  :title="$t('Religions.name')"
                  :imgName="'religions.svg'"
                />
                <TextArea
                  :className="'col-md-12'"
                  :id="`${id}-[${indexParent}]userParentData-userGeneralNotes`"
                  :value="
                    parent.userParentData.userProfileData.userGeneralNotes
                  "
                  :title="$t('notes')"
                  :imgName="'notes.svg'"
                  v-on:changeValue="
                    parent.userParentData.userProfileData.userGeneralNotes =
                      $event
                  "
                />
              </div>
            </div>
          </template>
          <div class="add-slice-container">
            <button
              @click="addSliceParent()"
              type="button"
              class="btn btn-add-slice"
              :title="$t('add')"
            >
              +
            </button>
          </div>
        </div>
      </template>

      <div class="form-actions">
        <div class="icon-submit" @click.prevent="submitForm">
          <img src="@/assets/images/check-icon.svg" :title="submitName" />
        </div>
        <div
          @click.prevent
          class="icon-cancel"
          v-b-modal="`ConfirmCloseSheet-${bottomSheetName}`"
        >
          <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  getLanguage,
  fullPathFileFromServer,
  bottomSheetScrollToTop,
} from "./../../utils/functions";
import {
  isValidUserNameAr,
  isValidUserNameEn,
  isValidEmail,
  // isValidTextAr,
  // isValidTextEn,
} from "./../../utils/validationHelper";
import CustomFileInput from "./../../components/general/CustomFileInput.vue";
import CustomInput from "./../../components/general/CustomInput.vue";
import CustomSelectBox from "./../../components/general/CustomSelectBox.vue";
import TextArea from "./../../components/general/TextArea.vue";
import generalMixin from "./../../utils/generalMixin";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import CustomInputPassword from "@/components/general/CustomInputPassword.vue";
import CustomInputFloat from "@/components/general/CustomInputFloat.vue";
import CustomPhonePicker from "@/components/general/CustomPhonePicker.vue";
import UserParentModel from "./../../models/users/UserParentModel";
import {
  getDialogOfGender,
  getDialogOfLocalJobType,
  getDialogOfReligionType,
  getDialogOfRelativeRelationType,
} from "./../../utils/dialogsOfConstantsLists";
import { USER_TYPE } from "./../../utils/constantLists";
import {
  getEstablishmentRolesDialog,
  getEducationalStagesDialog,
} from "./../../utils/dialogsOfApi";

export default {
  mixins: [generalMixin, validationMixin],
  components: {
    CustomFileInput,
    CustomInputPassword,
    CustomInputFloat,
    CustomPhonePicker,
    CustomInput,
    CustomSelectBox,
    TextArea,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: getLanguage(),
      establishmentRoleTokenOptions: [],
      userEducationalStageTokenOptions: [],
      userGenderTokenOptions: getDialogOfGender(),
      userLocalJobTokenOptions: getDialogOfLocalJobType(),
      userReligionTokenOptions: getDialogOfReligionType(),
      parentRelativeRelationTypeTokenOptions: getDialogOfRelativeRelationType(),
      USER_TYPE,
    };
  },
  props: {
    user: {
      type: Object,
    },
    userTypeToken: {
      type: String,
      default: "",
    },
    submitName: {
      type: String,
    },
    id: {
      type: String,
      default: "id",
    },
    deleteFileStatus: {
      type: Boolean,
      default: false,
    },
    bottomSheetName: {
      type: String,
      default: "",
    },
    disabledStatus: {
      type: Boolean,
      default: false,
    },
    updateWithParents: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    user: {
      userNameAr: { required, isValidUserNameAr },
      userNameEn: { required, isValidUserNameEn },
      userEmail: { isValidEmail },
    },
  },
  computed: {
    errors_userNameAr() {
      let errors = [];
      if (this.$v.user.userNameAr.$error) {
        if (
          !this.$v.user.userNameAr.required &&
          !this.$v.user.userNameEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.user.userNameAr.required &&
          !this.$v.user.userNameAr.isValidUserNameAr
        )
          errors.push(this.$t("validation.userNameIsRequiredInAr"));
      }
      return errors;
    },
    errors_userNameEn() {
      let errors = [];
      if (this.$v.user.userNameEn.$error) {
        if (
          !this.$v.user.userNameAr.required &&
          !this.$v.user.userNameEn.required
        )
          errors.push(this.$t("validation.fieldIsRequired"));
        else if (
          this.$v.user.userNameEn.required &&
          !this.$v.user.userNameEn.isValidUserNameEn
        )
          errors.push(this.$t("validation.userNameIsRequiredInEn"));
      }
      return errors;
    },
    errors_userEmail() {
      let errors = [];
      if (this.$v.user.userEmail.$error) {
        if (!this.$v.user.userEmail.isValidEmail)
          errors.push(this.$t("validation.fieldMustBeEmail"));
      }
      return errors;
    },
  },
  methods: {
    async getEducationalStagesDialog() {
      this.isLoading = true;
      this.userEducationalStageTokenOptions =
        await getEducationalStagesDialog();
      this.isLoading = false;
    },
    addSliceParent() {
      this.user.userParentsData.push(new UserParentModel());
    },
    removeSliceParent(index) {
      this.user.userParentsData.splice(index, 1);
    },
    setPhone(data) {
      this.user.userPhoneCountryCodeName = data.countryCodeName;
      this.user.userPhoneCountryCode = data.countryCode;
      this.user.userPhone = data.phoneNumber;
    },

    async getEstablishmentRoleDialog() {
      let modelName = "";
      switch (this.userTypeToken) {
        case USER_TYPE.AdminsEgypt:
          modelName = "adminsEgyptEstablishmentsRole";
          break;
        case USER_TYPE.MasterAdmin:
          modelName = "masterAdminEstablishmentsRole";
          break;
        case USER_TYPE.Employee:
          modelName = "employeeEstablishmentsRole";
          break;
        case USER_TYPE.Student:
          modelName = "studentEstablishmentsRole";
          break;
        case USER_TYPE.Supplier:
          modelName = "supplierEstablishmentsRole";
          break;
        case USER_TYPE.Parent:
          modelName = "parentEstablishmentsRole";
          break;
        case USER_TYPE.Client:
          modelName = "clientEstablishmentsRole";
          break;

        default:
          break;
      }
      this.isLoading = true;
      this.establishmentRoleTokenOptions = await getEstablishmentRolesDialog(
        modelName
      );
      this.isLoading = false;
    },

    async submitForm() {
      bottomSheetScrollToTop();
      this.$v.$touch();
      if (
        (this.$v.user.userNameAr.required &&
          this.$v.user.userNameAr.isValidUserNameAr) ||
        (this.$v.user.userNameEn.required &&
          this.$v.user.userNameEn.isValidUserNameEn &&
          this.$v.user.userEmail.isValidEmail)
      ) {
        this.$emit("submitForm");
      }
    },
    fullPathFileFromServer,
  },
  watch: {
    userTypeToken() {
      this.getEstablishmentRoleDialog();
      this.getEducationalStagesDialog();
    },
  },
  async created() {
    this.getEstablishmentRoleDialog();
    this.getEducationalStagesDialog();
  },
};
</script>
