import { METHOD, request } from "./../utils/request";
import { UserWalletTransactions } from "./api";

async function getAll(params) {
  return request(UserWalletTransactions.getAll, METHOD.GET, params);
}
async function getDetails(params) {
  return request(UserWalletTransactions.getDetails, METHOD.GET, params);
}
async function getUserWalletTransactionsReport(params) {
  return request(
    UserWalletTransactions.getUserWalletTransactionsReport,
    METHOD.GET,
    params
  );
}
async function getUserWalletTransactionReceiptReport(params) {
  return request(
    UserWalletTransactions.getUserWalletTransactionReceiptReport,
    METHOD.GET,
    params
  );
}

async function add(data) {
  return request(UserWalletTransactions.add, METHOD.POST, data);
}

export default {
  getAll,
  getDetails,
  getUserWalletTransactionsReport,
  getUserWalletTransactionReceiptReport,
  add,
};
